import React, { useRef, useState } from "react";
import { toastError, toastSuccess, toastWarning } from "../../Toast/Toast";
import { useNavigate } from "react-router-dom";
import { ThreeDotLoader } from "../../Loader/Loader";
// import { usePapaParse } from "react-papaparse";
import { useSearchParams } from 'react-router-dom';
import { LuList, LuPlus, LuUpload } from "react-icons/lu";
// import { DEFAULT_INSTRUCTIONS, DEFAULT_APIKEY, permissionState } from "../../../Constants/constants";
import IAgentService from "../../../Services/AgentServices/IAgentService";
import IGroupService from "../../../Services/GroupService/IGroupService";
import AgentService from "../../../Services/AgentServices/AgentService";
import GroupService from "../../../Services/GroupService/GroupService";
import TemplateService from "../../../Services/TemplateService/TemplateService";
// import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { saveTemplateData } from "../../../Stores/TemplateStore/TemplateAction";
import TemplateInfo from "./TemplateInfo";
import { ASSISTANT_INITIAL_OBJ } from "../../../Constants/constants";
import ModelsServices from "../../../Services/VendorAPIService/ModelService";
import IModelService from "../../../Services/VendorAPIService/IModelService";
import CreateGroup from "../../Group/CreateGroup";
import { TbTemplate } from "react-icons/tb";


interface Props {
    SetSelectedMainTab: React.Dispatch<React.SetStateAction<string>>;

}

const BasicDetails: React.FC<Props> = ({ SetSelectedMainTab }) => {
    const navigate = useNavigate();
    // const templateId = useSelector((state: any) => state.templateReducer)

    const [showLoader, setShowLoader] = useState(false);
    const [modelList, setModelList] = useState<IModelService[]>([]);
    const [groupList, setGroupList] = useState<IGroupService[]>([]);
    const [vendorAPIsList, setVendorAPIsList] = useState<IModelService[]>([]);
    const disableSubmitButton = React.useRef<boolean>(true)
    const fieldLengthCheck = React.useRef<boolean>(false)


    const [searchParams] = useSearchParams();
    const assId = searchParams.get('assistantId')
    const assistantId = React.useRef(assId ? assId : '');

    const isEditable = React.useRef<boolean>(false)
    const [imageName, setImageName] = useState("")


    const [showGroupFormPopup, setShowGroupFormPopup] = useState(false);

    const [showTemplateFormPopup, setShowTemplateFormPopup] = useState(false);
    const [enableTemplates, setEnableTemplates] = useState(false);
    const [selectedTemplate, setSeletedTemplate] = useState<string[]>([])
    const [openSection, setOpenSection] = useState<number | null>(null);

    const [activeTab, setActiveTab] = useState('All');
    const dispatch = useDispatch()
    const ISTxtlenExceeds = useRef(false)

    const [createItemForm, setCreateItemForm] = useState<IAgentService>({
        ...ASSISTANT_INITIAL_OBJ
    });
    const [createGroupForm, setCreateGroupForm] = useState({
        name: "",
        description: "",
        // Image: null as File | null
    });



    React.useEffect(() => {
        if (assistantId.current) {
            getAssistantById()
        }
        getAllModels();
        getAllGroups();
        getAllVendorKeys();


    }, []);
    React.useEffect(() => {
        getAllGroups();
    }, [showGroupFormPopup]);

    const getAllModels = () => {
        setShowLoader(true)
        ModelsServices.GetAllModels().then((res) => {
            setModelList(res.response)
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setShowLoader(false)
        })
    };

    const getAllGroups = () => {
        setShowLoader(true)
        GroupService.GetGroupList().then((res) => {
            if (createGroupForm.name) {
                setCreateItemForm({
                    ...createItemForm,
                    group: createGroupForm.name,
                });
            }

            setGroupList(res.response)
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setShowLoader(false)
        })
    };

    const getAllVendorKeys = () => {
        setShowLoader(true)
        ModelsServices.GetAllModels().then((res) => {
            setVendorAPIsList(res.response)
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setShowLoader(false)
        })
    };



    const getAssistantById = () => {
        setShowLoader(true)
        AgentService.GetAssistantById(assistantId.current ? assistantId.current : '').then(res => {
            // console.log("bot-res", res.response)
            setCreateItemForm(res.response)
            isEditable.current = true
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setShowLoader(false)
        })
    }

    const createForm = async () => {
        if (!validateAgentData()) return;
        setShowLoader(true);
        disableSubmitButton.current = true
        if (!assistantId.current) {
            AgentService.CreateAssistant(createItemForm).then(res => {
                toastSuccess("Agent Created Successfully")
                localStorage.setItem("assistantCreated", "true")
                if (createItemForm.templateId) dispatch(saveTemplateData(createItemForm.templateId))
                goChatBot(res.response);
            }).catch((err) => {
                toastError(err?.response?.data?.response)
                console.log(err)
            }).finally(() => {
                setShowLoader(false)
                disableSubmitButton.current = false
            })
        } else {
            AgentService.UpdateAssistant(assistantId.current, createItemForm).then(res => {
                toastSuccess("Agent Updated Successfully")
                SetSelectedMainTab("sources")
            }).catch((err) => {
                toastError(err?.response?.data?.response)
                console.log(err)
            }).finally(() => {
                setShowLoader(false)
                disableSubmitButton.current = false
            })
        }

    };


    const getAllTemplates = (groupName: string) => {
        setShowLoader(true);
        if (groupName) {
            TemplateService.GetAllTemplpates(groupName).then(res => {
                setEnableTemplates(res.response.length > 0 ? true : false);
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setShowLoader(false);
            })
        }
    }
    const cancelForm = () => {
        navigate('/Apps')
    };



    const validateAgentData = () => {
        let flag = true
        if (!createItemForm.name?.trim()) {
            toastError("Please Enter Agent Name")
            flag = false
        }
        if (createItemForm.name?.trim() && createItemForm.name.length > 20) {
            toastError("Name length should be 20 character")
            flag = false
        }
        if (createItemForm.nickName && createItemForm.nickName.length > 15) {
            toastError("NickName length should be 15 character")
            flag = false
        }
        if (!createItemForm.description?.trim()) {
            toastError("Please Enter Description")
            flag = false
        }
        if (!createItemForm.group) {
            toastError("Please Select Group")
            flag = false
        } else {
            if (groupList.length === 0) {
                toastError("Please Select Group")
                flag = false
            }
        }
        // if (!createItemForm.instruction) {
        //     toastError("Please Enter Instruction")
        //     flag = false
        // }
        if (!createItemForm.gptModel) {
            toastError("Please Select Model")
            flag = false
        }
        // if (!createItemForm.vendorKey) {
        //     toastError("Please Select Vendor Key")
        //     flag = false
        // } else {
        //     if (vendorAPIsList.length === 0) {
        //         toastError("Please Select Venor Key")
        //         flag = false
        //     }
        // }
        // if (!createItemForm.gptKey) {
        //     toastError("Please Enter GPT Key")
        //     flag = false
        // }
        return flag
    }

    const updateForm = (event: any) => {
        disableSubmitButton.current = false

        let name = event.target.name;
        let val = event.target.value;

        if (name === "Image") {
            const selectedImage = event.target.files[0];
            setImageName(selectedImage.name)
            const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png"];
            if (selectedImage && allowedImageTypes.includes(selectedImage.type)) {
                setCreateItemForm((prevState) => ({
                    ...prevState,
                    uploadIcon: selectedImage,
                }));
            } else {
                toastError("Please upload files type jpeg/ico/png")
            }
        } else if (name === "group" && val !== "") {
            setEnableTemplates(false)
            setSeletedTemplate([])
            setCreateItemForm({
                ...createItemForm,
                [name]: val,
                templateId: "",
                templateName: ""
            });
            getAllTemplates(val)

        }
        else if (name === "description") {
            val = val.replace(/[^A-Za-z\s!@#$*%]/ig, '')
            setCreateItemForm({
                ...createItemForm,
                [name]: val,
            });
        }
        else {
            setCreateItemForm({
                ...createItemForm,
                [name]: val,
            });
        }

    };


    function getTotalLength(inputVal: any) {
        if (inputVal === undefined) {
            return 0;
        }
        return inputVal.length + (inputVal.match(/\n/g) || []).length;
    }



    const goChatBot = async (assistantId: any) => {
        navigate({
            pathname: '/AgentDetails',
            search: `?assistantId=${assistantId ? assistantId : ""}`,
        });
        // SetSelectedMainTab("sources")
    }


    /// Group Module
    const showPopUP = async () => {
        setShowGroupFormPopup(true)
    }


    const closeEosPopup = () => {
        setShowGroupFormPopup(false);
        setCreateGroupForm({
            name: "",
            description: ""
        });
    };







    // Template Module
    const showTemplatePopUP = async () => {
        const message = createItemForm.group ? (enableTemplates ? setShowTemplateFormPopup(true) : "No templates found for this group.") : "Please select a group.";
        toastWarning(message);
    }




    return (
        <div className=" basic-holder with-guide-container">
            <div className="row h-100">
                <div className="col-12 col-md-12 col-lg-12 leftContainer h-100">
                    <form encType="multipart/form-data" className="h-100 form-container">
                        <div className="stepOne">

                            <div className="d-flex gap-4 basic-details-form ">
                                <div className="upload-button-container">
                                    <div className="uploadButton">
                                        {createItemForm.icon && !createItemForm.uploadIcon && (
                                            <div className="iconHolder">
                                                <img src={`data:${createItemForm.icon};base64,${createItemForm.icon}`} alt="" />
                                            </div>
                                        )}

                                        {/* before upload */}
                                        {!createItemForm.icon && !createItemForm.uploadIcon && (
                                            <div className="uploadTrigger">
                                                <div className="icon"><LuUpload /></div>
                                                <label className="form-label">
                                                    Upload Display Icon</label>
                                            </div>
                                        )}

                                        {/* preview */}
                                        {createItemForm.uploadIcon && (
                                            <div className="iconHolder">
                                                <img src={URL.createObjectURL(createItemForm.uploadIcon)} alt="Preview" />
                                            </div>
                                        )}
                                        {/* upload button */}
                                        <input
                                            type="file"
                                            name="Image"
                                            onChange={(event) => updateForm(event)}
                                            className="form-control"
                                            title={imageName}
                                            accept=".jpeg, .jpg, .png,.ico"

                                        />
                                    </div>
                                </div>
                                <div className="right-form-container">
                                    <div className="row">

                                        <div className="col-12 col-md-8 formHolder nameHolder mb-3">
                                            <label className="form-label">Agent Name <span className="danger">*</span></label>
                                            <input
                                                type="text"
                                                placeholder="Enter the name"
                                                name="name" className="form-control" onChange={(event) => updateForm(event)}
                                                value={createItemForm.name}
                                                disabled={isEditable.current}
                                                maxLength={21}
                                            />
                                            {getTotalLength(createItemForm.name) > 20 && (<div className="danger">Name cannot exceed 20 characters.</div>)}
                                        </div>
                                        {/* <div className="col-12 col-md-6 formHolder displayHolder mb-3">
                                            <label className="form-label">Nickname</label>
                                            <input
                                                type="text"
                                                placeholder="Enter the Nickname"
                                                name="nickName" className="form-control" onChange={(event) => updateForm(event)}
                                                value={createItemForm.nickName}
                                                maxLength={16}
                                            />
                                            {getTotalLength(createItemForm.nickName) > 15 && (<div className="danger">NickName cannot exceed 15 characters.</div>)}
                                        </div> */}
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-6 formHolder selectHolder mb-3">
                                            <label className="form-label">Group<span className="danger">*</span></label>
                                            <div className="d-flex gap-1">
                                                <select
                                                    value={createItemForm.group}
                                                    disabled={isEditable.current}
                                                    name="group"
                                                    onChange={(event) => updateForm(event)} className="form-select"
                                                >
                                                    <option value="">Select a Group</option>
                                                    {groupList && groupList.map((group) => (
                                                        <option value={group.name}>{group.name}</option>
                                                    ))}
                                                </select>
                                                <button className="addAgent-btn" type="button" disabled={isEditable.current} onClick={showPopUP}><LuPlus /></button>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 formHolder selectHolder mb-3">
                                            <label className="form-label">Templates</label>
                                            <div className="d-flex position-relative template-name-btn gap-1">
                                                <input
                                                    type="text"
                                                    placeholder="Template Name"
                                                    disabled={isEditable.current}
                                                    value={createItemForm.templateName}
                                                    className="form-control"
                                                />
                                                <button type="button" className="templates-btn" disabled={isEditable.current} onClick={showTemplatePopUP}><TbTemplate /></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-12 formHolder descHolder mb-3">
                                            <label className="form-label">Description <span className="danger">*</span></label>
                                            <textarea className="form-control height-80"
                                                placeholder="Enter the Description"
                                                name="description" onChange={(event) => updateForm(event)}
                                                value={createItemForm.description}
                                                maxLength={500}
                                            />
                                            {getTotalLength(createItemForm.description) > 500 && (<div className="danger">Description cannot exceed 500 characters.</div>)}
                                        </div>

                                        <div className="col-12 col-md-12 formHolder instruction-holder mb-3 position-relative">
                                            <label className="form-label">Instruction </label>
                                            <div className="info ">

                                                <strong>Note : </strong>  Specify how the agent should function by providing clear and concise instructions.
                                            </div>
                                            <textarea className="form-control height-80"
                                                placeholder="Enter the Instruction"
                                                name="instruction" onChange={(event) => updateForm(event)}
                                                value={createItemForm.instruction}
                                                maxLength={500}

                                            />
                                            {getTotalLength(createItemForm.instruction) > 500 && (<div className="danger">Instructions cannot exceed 500 characters.</div>)}
                                        </div>

                                        <div className="col-12 col-md-6 formHolder selectHolder mb-3">
                                            <label className="form-label">Model <span className="danger">*</span></label>
                                            <select
                                                value={createItemForm.gptModel}
                                                name="gptModel"
                                                disabled={isEditable.current || createItemForm.templateId ? true : false}
                                                onChange={(event) => updateForm(event)} className="form-select"
                                            >
                                                <option value="">Select a Model</option>
                                                {modelList && modelList.map((model) => (
                                                    // <option key={model['ModelId']} value={model['ModelId']}>{model['ModelId']}</option>
                                                    // <option key={model['ModelId']} value={model['ModelId']} style={{ color: (model['ModelId'] === "gpt-3.5-turbo-1106" || model['ModelId'] === "gpt-4-1106-preview") ? "#C00077" : "#2F1E3D" }}>{model['ModelId']}</option>
                                                    // (model['ModelId'] === "gpt-3.5-turbo-1106" || model['ModelId'] === "gpt-4-1106-preview") && (
                                                    //     <option key={model['ModelId']} value={model['ModelId']} style={{ color: "#C00077" }}>
                                                    //         {model['ModelId']}
                                                    //     </option>
                                                    // )
                                                    <option value={model.name} >{model.name}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="col-12 col-md-6 formHolder keyHolder mb-3">
                                            <label className="form-label"> <span className="danger">*</span></label>


                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="mt-2 btnHolder d-flex gap-3">
                            <button type="button" className="btn btn-secondary ms-auto" onClick={cancelForm}>Cancel</button>
                            {/* {(isEditable.current && permissionState.isCreateBot) && (<button className="btn btn-primary" type="button" onClick={editForm}>Save & Continue</button>)}
                            {(!isEditable.current && permissionState.isCreateBot) && (<button className="btn btn-primary" type="button" onClick={createForm}>Save & Continue</button>)} */}
                            <button className="btn btn-primary" type="button" disabled={disableSubmitButton.current}
                                onClick={createForm}>Save & Continue</button>
                        </div>

                    </form>
                </div>
            </div>



            {showGroupFormPopup && (
                <CreateGroup SetShowGroupFormPopup={setShowGroupFormPopup} SetCreateItemForm={setCreateItemForm} />
            )}


            {showTemplateFormPopup && (
                <div className="template-info">
                    <TemplateInfo GroupName={createItemForm.group ? createItemForm.group : ""}
                        SetShowTemplateFormPopup={setShowTemplateFormPopup}
                        SetCreateItemForm={setCreateItemForm}
                        SelectedTemplate={selectedTemplate}
                        SetSeletedTemplate={setSeletedTemplate} />
                </div>
            )}
            <ThreeDotLoader visibility={showLoader}></ThreeDotLoader>



        </div >
    );
};

export default BasicDetails;
