import React, { SetStateAction, useEffect, useRef, useState } from "react";
import { toastError, toastSuccess, toastWarning } from "../Toast/Toast";
import { useNavigate } from "react-router-dom";
import { ThreeDotLoader } from "../Loader/Loader";
import ModelsServices from "../../Services/VendorAPIService/ModelService";
import IModelService from "../../Services/VendorAPIService/IModelService";
import { FaTimes } from "react-icons/fa";



// import { permissionState } from "../../Constants/constants";
interface Props {
	HideShowForm:any
	ModelData?: IModelService|null
}

const ModelsForm: React.FC<Props> = ({ ModelData, HideShowForm }: any) => {

	const navigate = useNavigate();
	const [showLoader, setShowLoader] = useState(false);
	const disableSubmitButton = React.useRef<boolean>(false)
	const [modelList, setModelList] = useState(["OpenAI", "Gemini", "Anthropic", "others"])
	const submitButtonAction = useRef("Create")
	const disableButton = useRef(true)

	const [createModelForm, setCreateModelForm] = useState<IModelService>({
		name: "",
		apiKey: "",
		description: "",
		provider: "",
		endpoint: ""
		// Image: null as File | null
	});
	useEffect(() => {
		if (ModelData) {
			setCreateModelForm({ ...ModelData })
			submitButtonAction.current = "Update"
		}
	}, [])

	const updateForm = (target: any) => {
		disableButton.current = false
		let name = target.name;
		let val = target.value;
		setCreateModelForm({
			...createModelForm,
			[name]: val,
		});

	};


	const validateAgentData = () => {
		let flag = true
		if (!createModelForm.name?.trim()) {
			toastError("Please Enter Name")
			flag = false
		}
		if (!createModelForm.apiKey?.trim()) {
			toastError("Please Enter Model APIKey")
			flag = false
		}
		if (!createModelForm.description?.trim()) {
			toastError("Please Enter Description")
			flag = false
		}
		if (!createModelForm.provider?.trim()) {
			toastError("Please Select Provider")
			flag = false
		}
		if (createModelForm.provider === "others" && !createModelForm.endpoint?.trim()) {
			toastError("Please Enter Endpoint")
			flag = false
		}
		if (createModelForm.endpoint && (!createModelForm.endpoint?.startsWith("https://") || !createModelForm.endpoint?.startsWith("http://"))) {
			toastError("Endpoint Start With https:// or http:// ")
			flag = false
		}
		return flag
	}


	const createForm = async (event: React.FormEvent) => {
		event.preventDefault();
		if (!validateAgentData()) return;
		setShowLoader(true);
		disableSubmitButton.current = true;
		if(submitButtonAction.current=== "Create"){
			ModelsServices.CreateModel(createModelForm).then(res => {
				toastSuccess("Model APIKey Created Successfully");
				HideShowForm()
			}).catch(err => {
				disableSubmitButton.current = false;
				toastError(err?.response?.data?.response)
			}).finally(() => {
				setShowLoader(false)
			})
		}else{
			ModelsServices.UpdateModel(createModelForm).then(res => {
				toastSuccess("Model APIKey Updated Successfully");
				HideShowForm()
			}).catch(err => {
				disableSubmitButton.current = false;
				toastError(err?.response?.data?.response)
			}).finally(() => {
				setShowLoader(false)
			})
		}
		

	};



	function getTotalLength(inputVal: any) {
		return inputVal.length + (inputVal.match(/\n/g) || []).length;
	}


	return (
		<div className="popup-container medium-popup">
			<div className="shadeClose" onClick={() => HideShowForm(false)}></div>
			<div className="popup-inner">
				<div className="inner-design">
					<div className="popup-header">
						<h3>{ModelData ? "Edit Model":"Create Model"}</h3>
						<button
							type="button"
							onClick={() => HideShowForm(false)}
							className="close"
						><FaTimes /></button>
					</div>

					<div className="popup-content">
						<form encType="multipart/form-data" className="form-container">
							<div className="row">
								<div className="col-12 col-md-12 formHolder nameHolder mb-4">
									<label className="form-label">Name<span className="danger">*</span></label>
									<input
										type="text"
										placeholder="Enter the name"
										className="form-control"
										name="name"
										onChange={(event) => updateForm(event.target)}
										value={createModelForm.name}
										maxLength={16}
										readOnly={ModelData}
									/>
									{createModelForm.name && createModelForm.name.length > 15 && (<div className="danger">Name cannot exceed 15 characters.</div>)}
								</div>


								<div className="col-12 col-md-6 formHolder selectHolder mb-3">
									<label className="form-label">Provider <span className="danger">*</span></label>
									<select
										value={createModelForm.provider}
										name="provider"
										onChange={(event) => updateForm(event.target)} className="form-select"
									>
										<option value="">Select a Provider</option>
										{modelList && modelList.map((model) => (
											<option value={model} >{model}</option>
										))}
									</select>
								</div>
								{createModelForm.provider == "others" && <div className="col-12 col-md-12 formHolder nameHolder mb-4">
									<label className="form-label">Endpoint<span className="danger">*</span></label>
									<input
										type="text"
										placeholder="Enter the Endpoint"
										className="form-control"
										name="endpoint"
										onChange={(event) => updateForm(event.target)}
										value={createModelForm.endpoint}
									/>
								</div>}
								<div className="col-12 col-md-12 formHolder nameHolder mb-4">
									<label className="form-label">API Key<span className="danger">*</span></label>
									<input
										type="text"
										placeholder="Enter the model api key"
										className="form-control"
										name="apiKey"
										onChange={(event) => updateForm(event.target)}
										value={createModelForm.apiKey}
									/>
								</div>
								<div className="col-12 col-md-12 formHolder descHolder mb-4">
									<label className="form-label">Description <span className="danger">*</span></label>
									<textarea className="form-control"
										placeholder="Enter the Description"
										onChange={(event) => updateForm(event.target)}
										name="description"
										value={createModelForm.description}
										maxLength={500}
									/>
									{getTotalLength(createModelForm.description) > 499 && (<div className="danger">Description cannot exceed 500 characters.</div>)}
								</div>

								<div className="col-12 col-md-12 mt-2 btnHolder d-flex gap-3">
									<button type="submit" className="btn btn-secondary ms-auto" onClick={()=>HideShowForm(false)}>Cancel</button>
									<button className="btn btn-primary" type="submit" onClick={(e) => createForm(e)} disabled={disableButton.current}>{submitButtonAction.current}</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>

			<ThreeDotLoader visibility={showLoader}></ThreeDotLoader>

		</div >
	);
};

export default ModelsForm;
