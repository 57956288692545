import {
    CREATEASSISTANT, UPDATEASSISTANT, DELETEASSISTANT, GETASSISTANTLIST,
    GET_ASSISTANT_BY_ID, GET_ASSISTANT_FILES, DELETE_ASSISTANT_FILES, ASSISTANT_FILE_UPLOAD, SCRAP_WEBSITE,
    GET_MODELS,
    GET_ASSISTANT_LIST,
    GET_DATABASE_TABLES,
    ASSISTANT_ICON_UPLOAD,
    WEBSITE_LINKS,
    ASSISTANT_WEBSITE,
    ASSISTANT_FILE_DOWNLOAD,
    DOWNLOADCSV
} from "../EndPoint"
import TokenService from "../TokenService/TokenService";
import IResponse from "../IResponse";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { json } from "stream/consumers";
import instance, { multiPartInstance } from "../AxiosInstance";
import { rejects } from "assert";
import IAgentService from "./IAgentService";
import ITemplateService, { IAgentFile } from "../TemplateService/ITemplateService";
import { count, error } from "console";
import { createChunkFileForms, createFileChuncks as createFileChunks, ISAgentFile } from "../../Components/Utils/Utils";
import { IWebsite } from "./IwebsiteService";

export default class AgentService {

    private static axiosService = axios.create();
    private static userData = JSON.parse(TokenService.GetUserData())

    public static async CreateAssistant(assistantObj: IAgentService): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.post(CREATEASSISTANT, JSON.stringify(assistantObj)).then(res => {
                if (assistantObj.uploadIcon) {
                    // const iconFormData = new FormData()
                    // iconFormData.append('files', assistantObj.uploadIcon)
                    this.AssistantIconUpload(res?.data?.response, assistantObj).then(resF => {
                        resolve(res.data)
                    })

                } else
                    resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }


    private static async BasicDetailsUpdate(assistantId: string, agentObj: IAgentService): Promise<IResponse> {
        return new Promise(async (resolve, reject) => {
            let payLoad: IAgentService = {
                name: agentObj.name,
                nickName: agentObj.nickName,
                instruction: agentObj.instruction,
                description: agentObj.description,
                gptModel: agentObj.gptModel,
                vendorKey: agentObj.vendorKey,
                templateId: agentObj.templateId,
                icon: agentObj.icon,
                group: agentObj.group,
                chatOption: agentObj.chatOption,
                databaseConnections: agentObj.databaseConnections,
                FAQ: agentObj.FAQ,
                website: agentObj.website,
                prompts: agentObj.prompts,
                github: agentObj.github,
                apiKey: agentObj.apiKey,
                youtube: agentObj.youtube,
                slack: agentObj.slack,
                theme: agentObj.theme,
                iFrameFormShow: agentObj.iFrameFormShow,
                uploadFileIframe: agentObj.uploadFileIframe,

            }
            instance.put(`${UPDATEASSISTANT}/${assistantId}/`, JSON.stringify(payLoad))
                .then(res => {
                    resolve(res.data)
                }).catch(err => {
                    reject(err)
                })
        })
    }

    public static async UpdateAssistant(assistantId: string, agentObj: IAgentService, deletedFileNames?: string[]): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const promises: any = []
            const formData = new FormData();
            let filteredFiles = agentObj.files
            let newFiles: File[] = []
            let updateStatus: any
            filteredFiles?.map((file: any) => {
                if (!ISAgentFile(file)) {
                    newFiles.push(file)
                }

            })

            const formDatas = createChunkFileForms(assistantId, newFiles)
            formDatas?.map((formData: FormData) => {
                promises.push(
                    multiPartInstance.post(`${ASSISTANT_FILE_UPLOAD}/${assistantId}/upload-chunk`, formData).then(res => {

                    })
                )
            })
            promises.push(this.BasicDetailsUpdate(assistantId, agentObj).then(res => {
                updateStatus = res
            }))
            if (agentObj.uploadIcon) {
                promises.push(this.AssistantIconUpload(assistantId, agentObj))
            }
            await Promise.allSettled(promises).then((resP: any) => {
                let rejected = resP.filter((item: any) => item.status === "rejected")
                // console.log(chuncP)
                if (rejected.length > 0) {
                    reject(rejected[0]["reason"] ? rejected[0]["reason"] : '')
                } else {
                    resolve(updateStatus)
                }
            }).catch(err => {
                reject(err)
            })
        })
    }

    public static async UpdateAssistantBackup(assistantId: string, agentObj: IAgentService, deletedFileNames?: string[]): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const promises: any = []
            const formData = new FormData();
            let filteredFiles = agentObj.files
            let newFiles: File[] = []
            let updateStatus: any
            filteredFiles?.map((file: any) => {
                if (!ISAgentFile(file)) {
                    var ext = file.name.substr(file.name.lastIndexOf('.') + 1);
                    newFiles.push(file)
                    formData.append('files', file)

                }
            })
            const iconFormData = new FormData()
            if (agentObj.uploadIcon) {
                iconFormData.append('files', agentObj.uploadIcon)
            }
            let payLoad: IAgentService = {
                name: agentObj.name,
                nickName: agentObj.nickName,
                instruction: agentObj.instruction,
                description: agentObj.description,
                gptModel: agentObj.gptModel,
                vendorKey: agentObj.vendorKey,
                templateId: agentObj.templateId,
                icon: agentObj.icon,
                group: agentObj.group,
                chatOption: agentObj.chatOption,
                databaseConnections: agentObj.databaseConnections,
                FAQ: agentObj.FAQ,
                website: agentObj.website,
                prompts: agentObj.prompts,
                github: agentObj.github,
                apiKey: agentObj.apiKey,
                theme: agentObj.theme,
                iFrameFormShow: agentObj.iFrameFormShow,
                uploadFileIframe: agentObj.uploadFileIframe,

            }
            newFiles?.map(file => {
                let chunks = createFileChunks(file)
                let totalNumberOfChunks = chunks.length
                let fileId = uuidv4()
                let fileExtension = file.type
                let fileName = file.name
                // const promises: any = []
                // for (let chunckNumber = 0; chunckNumber < totalNumberOfChuncks; chunckNumber++) {
                chunks.map((chunk: any, index: number) => {
                    let formData = new FormData()
                    formData.append("file", chunk)
                    formData.append("chunkNumber", String(index + 1))
                    formData.append("totalChunks", String(totalNumberOfChunks))
                    formData.append("fileId", fileId)
                    formData.append("fileName", fileName)

                    promises.push(
                        multiPartInstance.post(`${ASSISTANT_FILE_UPLOAD}/${assistantId}/upload-chunk`, formData).then(res => {

                        })
                        // .catch(err => {
                        //     reject(err)
                        // })
                    )

                })
            })
            promises.push(
                instance.put(`${UPDATEASSISTANT}/${assistantId}/`, JSON.stringify(payLoad)).then(resM => {
                    updateStatus = resM.data
                })
                // .catch(err => {
                //     reject(err)
                // })
            )
            await Promise.allSettled(promises).then((resP: any) => {
                let rejected = resP.filter((item: any) => item.status === "rejected")
                if (rejected.length > 0) {
                    reject(rejected[0]["reason"] ? rejected[0]["reason"] : '')
                } else (resolve(updateStatus))
            }).catch(err => {
                reject(err)
            })
            // instance.put(`${UPDATEASSISTANT}/${assistantId}/`, JSON.stringify(payLoad)).then(async res => {

            //     newFiles?.map(file => {
            //         promises.push(
            //             this.AssistantMultipartFileUpload(assistantId, file).then(resM => {
            //                 console.log(resM)
            //             })
            //         )
            //     })
            //     // if (newFiles.length > 0) {
            //     //     promises.push(
            //     //         this.AssistantFileUpload(assistantId, formData).then(res => { })
            //     //     )
            //     // }
            //     // deletedFileNames?.map((file: string) => {
            //     //     promises.push(
            //     //         this.AssistantFileDelete(assistantId, file).then(res => { }).catch(err=>{reject(err)})
            //     //     )
            //     // })
            //     if (agentObj.uploadIcon) {
            //         promises.push(
            //             this.AssistantIconUpload(assistantId, iconFormData).then(res => { })
            //         )
            //     }
            //     await Promise.allSettled(promises).then(resP => {
            //         resolve(res.data)
            //     }).catch(err => {
            //         reject(err)
            //     })

            // }).catch(err => {
            //     reject(err)
            // })

        })
    }

    public static async GetAssistantById(assistantId: string): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.get(`${GET_ASSISTANT_BY_ID}/${assistantId}/`).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    }

    public static async GetAssistantList(groupNmae: string, pageSize: number, pageNumber: number, searchText?: string): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.get(`${GET_ASSISTANT_LIST}?limit=${pageSize}&page=${pageNumber}&search=${searchText}&group=${groupNmae}`).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    public static async AssistantFileDownload(assistantId: string, fileName: string): Promise<any> {
        return new Promise((resolve, reject) => {
            instance.get(`${ASSISTANT_FILE_DOWNLOAD}/${assistantId}/downloads?name=${fileName}`, { responseType: 'blob' }).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    }
    public static DeleteAssistant(assistantId: string): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.delete(`${DELETEASSISTANT}/${assistantId}/`).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    // public static async GetAllModels(): Promise<IResponse> {
    //     return new Promise((resolve, reject) => {
    //         instance.get(GET_MODELS).then(res => {
    //             resolve(res.data)
    //         }).catch(err => {
    //             reject(err.response.data)
    //         })
    //     })
    // }

    public static async AssistantFileUpload(assistantId: string, data: FormData): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            multiPartInstance.post(`${ASSISTANT_FILE_UPLOAD}/${assistantId}/uploads`, data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    public static async AssistantFileDelete(assistantId: string, fileName: string): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            multiPartInstance.delete(`${ASSISTANT_FILE_UPLOAD}/${assistantId}/file?filename=${fileName}`).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    public static async AssistantIconUpload(assistantId: string, agentObj: IAgentService,): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            const iconFormData = new FormData()
            iconFormData.append('files', agentObj.uploadIcon)
            multiPartInstance.post(`${ASSISTANT_ICON_UPLOAD}/${assistantId}/icon`, iconFormData).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })

        })
    }

    public static async AssistantMultipartFileUpload(assistantId: string, files: File[]): Promise<IResponse> {
        return new Promise(async (resolve, reject) => {
            const fileForms = createChunkFileForms(assistantId, files)
            const promises: any = []
            fileForms.map(formData => {
                promises.push(
                    multiPartInstance.post(`${ASSISTANT_FILE_UPLOAD}/${assistantId}/upload-chunk`, formData).then(res => {
                        // resolve(res.data)
                    })
                    // .catch(err => {
                    //     reject(err)
                    // })
                )
            })
            await Promise.allSettled(promises).then(res => {
                resolve(promises)
            }).catch(err => {
                reject(err)
            })

        })
    }


    //database tables
    public static async GetDatabaseTables(databaseObj: any): Promise<IResponse> {
        let payLoad = {
            "databaseName": databaseObj.databaseName,
            "databaseType": databaseObj.databaseType,
            "password": databaseObj.password,
            "port": databaseObj.port,
            "server": databaseObj.server,
            "user": databaseObj.user,
        }
        return new Promise((resolve, reject) => {
            instance.post(GET_DATABASE_TABLES, JSON.stringify(payLoad)).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    public static async GetWebSiteLinks(url: string): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            let data = { website: url }
            instance.post(WEBSITE_LINKS, JSON.stringify(data)).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
    public static async GetAssistantWebSite(url: string, assistantId: string): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.get(`${ASSISTANT_WEBSITE}/${assistantId}/websites?website=${url}`).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
    public static async UpdateAssistantWebSiteUpdate(url: string, wsData: IWebsite[], assistantId: string): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            let data: any = { urls: wsData, url: url }
            instance.put(`${ASSISTANT_WEBSITE}/${assistantId}/websites`, JSON.stringify(data)).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
    public static async DeleteWebSite(website: string, assistantId: string): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.delete(`${ASSISTANT_WEBSITE}/${assistantId}/websites?website=${website}`).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }


    public static async DownloadCSV(instruction: any, assistantId: any, sessionId: any): Promise<any> {
        console.log("ins",instruction)
        console.log("assistantId",assistantId)
        let itemObj = {
            'pandas_instr': instruction,
            'assistant': assistantId,
            'session_id': sessionId
        }
        return new Promise((resolve, reject) => {
            instance.post(DOWNLOADCSV, JSON.stringify(itemObj)).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
    public static async DeleteYoutubeLink(YTlink: string, assistantId: string): Promise<IResponse> {
        return new Promise((resolve, reject) => {
            instance.delete(`${ASSISTANT_WEBSITE}/${assistantId}/delete-youtube-video?videoURL=${YTlink}`).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

