import React, { useState, useEffect, ChangeEvent, useRef, SetStateAction } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { GoPlus } from "react-icons/go";
import {
  MSAL_AUTHORITY,
  MSAL_CLIENT_ID,
  MSAL_REDIRECT_URL,
} from "../../Constants/constants";
import IAgentService from "../../Services/AgentServices/IAgentService";
import { toastError, toastSuccess, toastWarning } from "../Toast/Toast";
import { ThreeDotLoader } from "../Loader/Loader";
import "./Login.scss";
import { LuLock, LuUser, LuCheck, LuMail, LuEyeOff, LuEye } from "react-icons/lu";
import TokenService from "../../Services/TokenService/TokenService";
import { isExpired, decodeToken } from "react-jwt";
import UserManagementService from "../../Services/UserManagement/UserManagementService";
import IUser from "../../Services/UserManagement/IUserManagementService";
interface Props {
  SetUser: React.Dispatch<SetStateAction<IUser>>

}


const Login: React.FC<Props> = ({ SetUser }) => {
  const [showLogin, setLogin] = useState(false);
  const [msalInstance, setMsalInstance] = useState<
    PublicClientApplication | any
  >();
  const [userName, setUsername] = useState<string>("");

  const [isMsalInitialized, setIsMsalInitialized] = useState(false);
  const [showAgentList, setShowAgentList] = useState(true);
  const [showCreateAgent, setShowCreateAgent] = useState(false);
  const [showChatBot, setShowChatBot] = useState(false);
  const [agentList, setAgentList] = useState<IAgentService[]>([]);
  const [selectedAssistantDetails, setSelectedAssistantDetails] = useState("");
  const [selectedAssistantId, setSelectedAssistantId] = useState("");
  const navigate = useNavigate();
  const [showLoader, setshowLoader] = useState(false);
  const [showSIgnUp, setShowSIgnUp] = useState(false);
  const [showSIgnIn, setShowSIgnIn] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showPasswordFailed, setShowPasswordFailed] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);



  useEffect(() => {
    let token = TokenService.GetToken()
    if (token) {
      navigate("/Dashboard");
    }
  }, [])


  useEffect(() => {
    const initializeMsal = async () => {
      const msalConfig = {
        auth: {
          clientId: MSAL_CLIENT_ID,
          authority: MSAL_AUTHORITY,
          redirectUri: MSAL_REDIRECT_URL,
        },
      };

      const instance = new PublicClientApplication(msalConfig);

      await instance.initialize();

      setIsMsalInitialized(true);
      setMsalInstance(instance);

      await instance.handleRedirectPromise();
    };

    initializeMsal();
  }, [setMsalInstance]);












  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };


  const handleSocialLogin = async () => {
    setLogin(true);
    try {
      const loginRequest = {
        scopes: ["7f971c6d-abbe-43b3-9e49-45f1f9ac85a0/.default"],
      };
      await msalInstance.loginPopup();
      const account = msalInstance.getAllAccounts()[0];
      if (account) {
        const response = await msalInstance.acquireTokenSilent({
          ...loginRequest,
          account: account,
        });
        // Use the response.accessToken as needed
        const token = response.accessToken;
        TokenService.SetToken(token);
        const user: any = decodeToken(token);
        const Expired = isExpired(token);
        TokenService.SetUserData(user);
        console.log(user)
        SetUser(user)
        setUsername(user.name);
        if (token) {
          navigate("/Dashboard");
        }
        // handleTogglePopup()
      }
    } catch (error) {
      console.log("handleLogin-error", error);
    }
  };

  const goChatBot = (e: any, agent: IAgentService) => {
    navigate({
      pathname: "/Dashboard",
    });
  };



  const signUpView = () => {
    setShowSIgnIn(false);
    setShowSIgnUp(true);
    setShowPassword(false)
    setShowConfirmPassword(false)
    setloginForm({
      email: "",
      password: "",
    });
  };
  const loginView = () => {
    setShowSIgnIn(true);
    setShowSIgnUp(false);
    setShowPassword(false)
    setShowConfirmPassword(false)
    setCreateUserForm({
      email: "",
      firstName: "",
      password: "",
      lastName: "no val",
      salt: "salt",
      verified: 1,
      rePassword: ""
    });
    setShowPasswordFailed(false)
  };

  const [createUserForm, setCreateUserForm] = useState({
    email: "",
    firstName: "",
    password: "",
    lastName: "no val",
    salt: "salt",
    verified: 1,
    rePassword: "",
  });
  const [loginForm, setloginForm] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    var { name, value } = event.target;
    if (name == "firstName") {
      value = value.replace(/[^A-Za-z" "]/ig, '')
    }
    setCreateUserForm({
      ...createUserForm,
      [name]: value,
    });
  };

  const handleLoginInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    var { name, value } = event.target;
    if (name === "firstName") {
      value = value.replace(/[^A-Za-z]/ig, '')
    }
    setloginForm({
      ...loginForm,
      [name]: value,
    });
  };


  const validateSignUpData = () => {
    let flag = true;

    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(createUserForm.email.trim())) {
      toastError("Please Enter Valid Email");
      flag = false;
    }
    if (!createUserForm.firstName.trim()) {
      toastError("Please Enter Name");
      flag = false;
    }
    // if (!createUserForm.phoneNumber || createUserForm.phoneNumber.length !== 10) {
    //   toastError("Please Enter Phone 10 digit");
    //   flag = false;
    // }
    // if (!createUserForm.lastName.trim()) {
    // 	toastError("Please Enter LastName");
    // 	flag = false;
    // }
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,20}$/;
    if (!createUserForm.password.trim()) {
      toastError("Please Enter Password");
      flag = false;
    } else if (!passwordRegex.test(createUserForm.password)) {
      setShowPasswordFailed(true)
      // toastError("Password must contain at least one alphabet, one number, one special character, and be at least 8 characters long");
      flag = false;
    }
    if (!createUserForm.rePassword.trim()) {
      toastError("Please Enter Confirm Password");
      flag = false;
    }
    if (createUserForm.password && createUserForm.rePassword && passwordRegex.test(createUserForm.password) && createUserForm.password !== createUserForm.rePassword) {
      setShowPasswordFailed(false)
      toastError("Password Mismatch")
      flag = false;
    }
    return flag;
  };

  const OnSignInClick = () => {
    if (!validateSignUpData()) return;
    var responseData = UserManagementService.CreateUser(createUserForm);
    responseData.then(async (res) => {
      if (res.statusCode === 200) {
        toastSuccess("User Created Successfully, Please contact Admin to approve");
        loginView()
      } else {
        toastError("Error Creating User");
      }
    });
  };

  const validateLoginForm = () => {
    if (!loginForm.email && !loginForm.password) {
      toastError("Please Enter Email and Password")
      return false
    }
    if (!loginForm.email) {
      toastError("Please Enter Email")
      return false

    }
    if (!loginForm.password) {
      toastError("Please Enter Password")
      return false
    }

    return true
  }

  const handleLoginInternal = async () => {
    if (!validateLoginForm()) return
    UserManagementService.LoginUser(loginForm).then(res => {
      const response = res.response;
      const token = response.serviceToken;
      const user = response.user

      TokenService.SetToken(token);
      // const user: any = decodeToken(token);
      const Expired = isExpired(token);
      TokenService.SetUserData(user);
      console.log(user)
      SetUser(user)
      setUsername(user.name);
      if (token) {
        navigate("/Dashboard");
      }
    }).catch(err => {
      toastError(err?.response?.data?.response)
    })
  };

  return (
    <section className="d-flex loginWrapper">
      <section className="leftWrapper d-flex p-4 col col-lg-6 col-sm-12 flex-column">
        <a className="logo d-flex gap-3 align-items-center mb-3">
          <span className="icon">
            <img src={require("../../common/images/logo.png")} />
          </span>
          <span className='logoTxt'>zeaiQ.ai</span>
        </a>
        {/* login area */}
        {showSIgnIn && (
          <div className="loginCover d-flex flex-column justify-content-center align-items-center">
            <div className="scrollDiv">
              <div className="maxWidth">
                <h1 className="mb-2">
                  <span> Welcome to</span> zeaiQ.ai
                </h1>

                <div className="formHolder mb-4 spacing-fields">
                  <form onSubmit={(e) => {
                    e.preventDefault()
                    handleLoginInternal()
                  }}>
                    <div className="input-group mb-4">
                      <label htmlFor="Username" className="form-label">
                        Email
                      </label>
                      <div className="inputHolder">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your email"
                          aria-label="Username"
                          name="email"
                          value={loginForm.email}
                          onChange={handleLoginInputChange}
                        />
                        <span>
                          {" "}
                          <LuUser />{" "}
                        </span>
                      </div>
                    </div>

                    <div className="input-group mb-4">
                      <label htmlFor="Password" className="form-label">
                        Password
                      </label>
                      <div className="inputHolder">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          placeholder="password"
                          aria-label="Password"
                          name="password"
                          value={loginForm.password}
                          onChange={handleLoginInputChange}
                        />
                        <span onClick={handleTogglePassword}>
                          {showPassword ? <LuEyeOff /> : <LuEye />}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      {/* <div className="form-check d-flex align-items-center">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="RememberMe"
                        /> */}
                      {/* for design only */}
                      {/* <div className="checkDesignHolder">
                          <span>
                            <LuCheck />
                          </span>
                        </div> */}
                      {/* for design only */}
                      {/* <label className="form-check-label" htmlFor="RememberMe">
                          Remember Me
                        </label>
                      </div> */}

                      <a href="/ResetPassword">Forgot Password?</a>
                    </div>
                    <div className="d-flex gap-3 mb-3 btnHolder">
                      <button
                        type="submit"
                        className="btn btn-primary"
                      // onClick={}
                      >
                        Login
                      </button>
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        onClick={signUpView}
                      >
                        Sign Up
                      </button>
                    </div>
                  </form>
                </div>
                <div className="bottomHolder d-flex justify-content-center flex-column">
                  {/* <div className="innerDiv">
                    <p>
                      <span>Or Login With</span>
                    </p>
                    <div className="d-flex justify-content-center gap-3 btnHolder">
                      <button>
                        <img src={require("../../common/images/google.png")} />
                      </button>
                      <button onClick={handleSocialLogin} disabled={true}>
                        <img
                          src={require("../../common/images/microsoft.png")}
                        />
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Sign Up Area */}
        {showSIgnUp && (
          <div className="loginCover d-flex flex-column justify-content-center align-items-center">
            <div className="scrollDiv">
              <div className="maxWidth maxWidth_600">

                <h1 className="mb-2">
                  Sign Up <span>to Continue to</span> zeaiQ.ai
                </h1>

                <h2 className="mb-5">
                  {/* <span>Chatbot Built with</span> Artificial Intelligence */}
                </h2>
                <div className="formHolder mb-4">
                  <div className="row col-md-6">
                    <div className="input-group mb-4">
                      <label htmlFor="Username" className="form-label">
                        Email
                      </label>
                      <div className="inputHolder">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter your email id"
                          aria-label="Username"
                          name="email"
                          value={createUserForm.email}
                          onChange={handleInputChange}
                        />
                        <span>
                          {" "}
                          <LuMail />{" "}
                        </span>

                      </div>
                    </div>
                  {/* </div> */}

                  <div className="input-group mb-4">
                    <label htmlFor="Username" className="form-label">
                      Full Name
                    </label>
                    <div className="inputHolder">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your full name"
                        aria-label="fullName"
                        name="firstName"
                        value={createUserForm.firstName}
                        onChange={handleInputChange}
                      />
                      <span>
                        {" "}
                        <LuUser />{" "}
                      </span>

                    </div>
                  </div>


                  <div className="input-group mb-4">
                    <label htmlFor="Password" className="form-label">
                      Password
                    </label>
                    <div className="inputHolder">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="Password"
                        aria-label="Password"
                        name="password"
                        value={createUserForm.password}
                        onChange={handleInputChange}
                      />
                      <span onClick={handleTogglePassword}>
                        {showPassword ? <LuEyeOff /> : <LuEye />}
                      </span>
                    </div>
                  </div>


                  <div className="input-group mb-4">
                    <label htmlFor="Password" className="form-label">
                      Confirm Password
                    </label>
                    <div className="inputHolder">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="Confirm Password"
                        aria-label="Password"
                        name="rePassword"
                        ref={inputRef}
                        value={createUserForm.rePassword}
                        onChange={handleInputChange}
                      />
                      <span onClick={handleToggleConfirmPassword}>
                        {showPassword ? <LuEyeOff /> : <LuEye />}
                      </span>
                    </div>
                  </div>


                  <div>
                    {showPasswordFailed && (
                      <div className="danger">Password must be 8-20 characters with at least one number and one special character.</div>
                    )}
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <p>
                      {" "}
                      Already a user? <a onClick={loginView}>Login</a>
                    </p>
                  </div>
                  <div className="d-flex gap-3 mb-3 btnHolder">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={OnSignInClick}
                    >
                      Sign Up
                    </button>
                    <button
                      type="submit"
                      className="btn btn-secondary"
                      onClick={loginView}
                    >
                      Login
                    </button>
                  </div>
                  </div>

                </div>
                <div className="bottomHolder d-flex justify-content-center flex-column">
                  <div className="innerDiv">
                    <p>
                      <span>Or Sign Up With</span>
                    </p>
                    <div className="d-flex justify-content-center gap-3 btnHolder">
                      <button>
                        <img src={require("../../common/images/google.png")} />
                      </button>
                      <button onClick={handleSocialLogin}>
                        <img
                          src={require("../../common/images/microsoft.png")}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      <section className="rightWrapper p-4 col-lg-6 col-sm-6 d-none  d-lg-flex">
        <div className="scrollDiv">
          <div className="slideDiv">
            <h1 className="mb-3">
              <span>Why</span> zeaiQ.ai?
            </h1>


            <p>zeaiQ.ai offers a centralized data hub, utilizing generative AI to answer internal questions
              for each department of an organization</p>
            <p>Build AI solutions faster with prebuilt models or train models using your data to innovate securely and at scale</p>
            <p>Deploy AI models and flows for use in websites, applications, or other production environments</p>

            <div className="imgHolder">
              <img src={require("../../common/images/chat.png")} />
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Login;
