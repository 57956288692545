import React, { useState } from "react";
import { SetStateAction } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { LuChevronDown } from "react-icons/lu";
import './Summary.scss'

interface AccordionItemProps {
    Data: any;
    openTab: string | null;
    setOpenTab: React.Dispatch<SetStateAction<string | null>>;
}

const AccordionFAQItem: React.FC<AccordionItemProps> = ({
    Data,
    openTab,
    setOpenTab,
}) => {
    const [openSection, setOpenSection] = useState<number | null>(0);

    return (
        <div className="accordion-item">
            <div
                className="accordion-title"
                onClick={() =>
                    setOpenTab((preVal) => (preVal === "FAQ" ? null : "FAQ"))
                }
            >
                {`FAQ (${Data.length || 0})`}
                <span className={`arrow ${openTab === "GH" ? "rotate" : ""}`}>
                    <svg width="14.828" height="8.414" viewBox="0 0 14.828 8.414">
                        <path
                            id="Path_491"
                            data-name="Path 491"
                            d="M6,9l6,6,6-6"
                            transform="translate(-4.586 -7.586)"
                            fill="none"
                            stroke="#615070"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                        />
                    </svg>
                </span>
            </div>
            <div className={`accordion-content ${openTab === "FAQ" ? "show" : ""}`}>
                {Data &&
                    Data.map((item: any, index: any) => (
                        <div className="summary-faq">
                            <div className="question">
                                {item.question}
                            </div>
                            <div className="answer">
                                {item.answer}
                            </div>
                            {/* <input
                                type="text"
                                placeholder="Please enter the Question "
                                name="question"
                                className="form-control"
                                defaultValue={item.question}
                                readOnly={true}
                            />
                            <textarea
                                className="form-control"
                                name="answer"
                                placeholder="Enter the Answer"
                                readOnly={true}
                                defaultValue={item.answer}
                            /> */}
                        </div>
                    ))}

                {Data.length === 0 &&
                    <div className="div-no-record">
                        <p>There are no records to display</p>
                    </div>
                }


            </div>
        </div>
    );
};
export default AccordionFAQItem;
